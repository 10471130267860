import $ from 'jquery';
import shared from '../scripts/shared';
import smoothscroll from 'smoothscroll-polyfill';

function scrollTo(target) {
	target = (target instanceof $ ? target.get(0) : target);

	var targetTop = target.getBoundingClientRect().top + window.scrollY;
	var offsetTop = shared.headerHeight + shared.scrollOffset;

	// "scrollTo" need to Safari polyfill
	window.scrollTo({
		top: targetTop - offsetTop,
		behavior: 'smooth'
	});
}

$.addInit(function(context){
	var base = '.i-accordion';
	$(base, context).each(function(){
		var component = $(this);
		var headers = component.find(base + '-header');
		var items = component.find(base + '-item');

		var isAccordion = component.data('accordion') !== 'open';
		var duration = 300;

		function open(item) {
			if (isAccordion) {
				items.filter('.m-open').each(function(){
					close($(this));
				});
			}

			// setTimeout(function() {
			// 	scrollTo(item);
			// }, duration);

			item.find(base + '-body').slideDown(duration);
			item.addClass('m-open');
		}

		function close(item) {
			item.find(base + '-body').slideUp(duration);
			item.removeClass('m-open');
		}

		function toggle(item) {
			if (!item.hasClass('m-open')) {
				open(item);
			} else {
				close(item);
			}
		}

		headers.on('click', function() {
			toggle($(this).closest(items));
		});
	});
});
