import $ from 'jquery';

$.addInit(function(context) {
	var base = '.i-printPage';

	$(base, context).each(function(){
		var component = $(this);

		component.on('click', function(e) {
			e.preventDefault();
			window.print();
		});
	});
});
