import $ from 'jquery';
import Swiper from '../../node_modules/swiper/js/swiper.min.js';

$.addInit(function(context) {
	var base = '.b-mediaSlider';
	$(base, context).each(function() {
		var component = $(this);

		new Swiper(component.find(base + '-container').get(0), {
			slidesPerView: 1,
			spaceBetween: 30,
			slideClass: 'b-media',
			navigation: {
				prevEl: component.find(base + '-prev'),
				nextEl: component.find(base + '-next'),
			},
			breakpoints: {
				1024: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
			},
		});
	});
});
