import breakpoint from '../scripts/breakpoint';
import $ from 'jquery';

$.addInit(function(context){
	var base = '.i-menu';
	$(base, context).each(function(){
		var component = $(this);
		var links = component.find(base + '-link');
		var items = component.find(base + '-item');

		var isAccordion = component.data('accordion') !== 'open';
		var duration = 300;

		function open(item) {
			if (isAccordion) {
				items.filter('.m-open').each(function(){
					close($(this));
				});
			}

			setTimeout(function() {
				scrollTo(item);
			}, duration);

			item.find(base + '-sub').slideDown(duration);
			item.addClass('m-open');
		}

		function close(item) {
			item.find(base + '-sub').slideUp(duration);
			item.removeClass('m-open');
		}

		function toggle(item) {
			if (!item.hasClass('m-open')) {
				open(item);
			} else {
				close(item);
			}
		}

		function actionMenu(e) {
			e.preventDefault();
			toggle($(this).closest(items));
		}

		function isTouchDevice() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				links.one('click', false);
			} else {
				links.off('click');
			}
		}


		breakpoint('xs', 'lg')
			.onmatch(function(){
					links.on('click', actionMenu);
				})
				.onmismatch(function(){
					links.off('click', actionMenu);

					isTouchDevice();
				});
	});
});
