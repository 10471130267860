import $ from 'jquery';
import bodyScroll from '../scripts/bodyScroll';
import breakpoint from '../scripts/breakpoint';
import shared from '../scripts/shared';

$.addInit(function(context){
	var base = '.b-head';
	$(base, context).each(function(){
		var component = $(this);
		var humburger = component.find('.b-humburger');
		var isActive = false;

		function toggleMenu() {
			isActive = !isActive;

			component.toggleClass('m-active');
			humburger.toggleClass('m-active');

			if (isActive) {
				bodyScroll.disable($(base + '-menu' ).get(0), {reserveScrollBarGap: true});
			} else {
				bodyScroll.enable($(base + '-menu' ).get(0));
			}
		}

		breakpoint(null, 'lg')
			.onmatch(function(){
				humburger.on('click', toggleMenu);
			})
			.onmismatch(function() {
				humburger.off('click', toggleMenu);
			});

		shared.headerHeight = component.height();
	});
});
